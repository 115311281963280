import React from 'react';
import * as styles from './HeroSection.module.css';

/**
 * HeroSection コンポーネント
 *
 * このコンポーネントは背景画像とお問い合わせリンクを含むヒーローセクションを描画します。
 * 背景画像のパスとリンクURLは、プロパティを使用してカスタマイズ可能です。
 *
 * @param {string} imagePath - 背景画像のパス。
 * @param {string} ctaLink - お問い合わせボタンのリンク先URL。
 * @param {string} buttonText - ボタンに表示されるテキスト。
 * @returns {JSX.Element} レンダリングされたヒーローセクション。
 *
 * * @example
 * // HeroSection の使用例:
 *
 *  <HeroSection
 *    imagePath="/path/to/background.jpg"
 *    ctaLink="https://example.com/contact"
 *    buttonText="お問い合わせはこちら"
 *  />
 *
 * @example
 * // CTAリンクやボタンテキストを指定しない場合:
 *
 *  <HeroSection
 *    imagePath="/path/to/background.jpg"
 *  />
 */

type HeroSectionProps = {
  imagePath: string;
  ctaLink?: string;
  buttonText?: string;
};

const HeroSection: React.FC<HeroSectionProps> = ({
  imagePath,
  ctaLink,
  buttonText,
}) => {
  return (
    <div className={styles.top}>
      <div className={styles.background}>
        <img src={imagePath} alt="背景画像" />
        {ctaLink && buttonText && (
          <div className={styles.textArea}>
            <a href={ctaLink} className={styles.ctaButton}>
              {buttonText}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeroSection;
