import * as React from 'react';
import HeroSection from '../components/HeroImageSection/HeroSection';
import Layout from '../components/layout';
import Seo from '../components/seo';

const NotfoundPage = ({ data }) => {
  return (
    <Layout>
      <HeroSection imagePath="/404image.jpg" />
    </Layout>
  );
};

export default NotfoundPage;

export const Head = () => (
  <Seo
    title="404 Not Found"
    description="お探しのページが見つかりませんでした。"
  />
);
